@import "../../styles/functions";
.LoginMainContent {
  composes: app-login-main-content from global;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: unset;
}
img.Logo {
  height: 2.7rem;
  margin-bottom: 2.3rem;
}
.Field {
  margin-bottom: 1.76rem !important;
}
.Error {
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - #{rem(25px)}));
  left: -1rem;
  right: -1rem;
  h4 {
    color: white;
    margin-bottom: 0.6741rem;
  }
  :global(.MuiAlert-root) {
    padding: 1.58824rem;
    margin-bottom: 1.47rem;
  }
  :global(.MuiAlert-message) {
    padding: 0;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
}
.Forgot {
  font-size: rem(14px);
}
