img.Logo {
  height: 2.7rem;
  margin-bottom: 2.3rem;
}
.Desc {
  composes: text-left from global;
  ul {
    margin-top: 1rem;
    padding-inline-start: 1rem;
  }
}
.colorRed {
  color: red;
  text-align: center;
  margin-bottom: 10px !important;
}
