@import "../../../styles/functions";

img.Logo {
  height: 2.7rem;
}
.bodyMainCodeVerificationFail {
  width: 100vw;
  height: 100vh;
  background: "#FFFFFF";
}
.header {
  height: rem(116px);
  width: 100%;
  box-shadow: 0px 2px 8px rgba(35, 24, 21, 0.1);
}
