@import "../../styles/global/_global-dir";

.PageHeading {
  composes: d-sm-flex justify-content-sm-between align-items-sm-center from global;
  border-radius: 3px;
  padding: rem(16px) rem(24px);
  margin-bottom: rem(11px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14);

  > :global(.MuiLink-root) {
    font-weight: 500;
    font-size: rem(17px);
    line-height: 1;
    color: #0080ff;
    display: flex;
    padding-right: rem(8px);
    align-items: center;
    svg {
      width: rem(18px);
      height: auto !important;
      margin-right: rem(8px) !important;
    }
  }

  h2 {
    flex: 0 0 auto;
    font-size: rem(18px);
    display: inline-block;
    margin-top: rem(2px);
    font-weight: 700;
    line-height: rem(21px);
    color: #000000;
    margin-bottom: rem(8px);
  }

  :global(.breadcrumb-item + .breadcrumb-item::before) {
    font-size: rem(16px);
  }

  :global(.breadcrumb) {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  :global(.breadcrumb-item) {
    //color: $light-gray;
    @include screen-1366p {
      font-size: rem(14px);
    }
    display: flex;
    align-items: center;

    font-size: rem(16px);
    line-height: rem(24px);
    color: #808080;
    &::before {
      font-size: rem(16px) !important;
      padding-right: rem(8px);
    }

    + :global(.breadcrumb-item) {
      padding-left: rem(10px)
    }

    svg {
      font-size: rem(22px) !important;
    }

    &,
    &:hover {
      text-decoration: none;
    }

    :global(.MuiLink-root) {
      color: inherit;

      &,
      &:hover {
        text-decoration: none;
      }
    }
  }
}
