@import "../../../styles/functions";

.AuthMainContent {
  margin: 20px;
  composes: app-login-main-content from global;
  &:global(.app-login-main-content) {
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: unset;

    form {
      display: flex;
      flex-direction: column;
    }
  }
}
img.Logo {
  height: 2.7rem;
  margin-bottom: 2rem;
}
.version {
  color: "#9E9E9E";
  text-align: center;
}
.versionCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
