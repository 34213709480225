.linkDownload {
  color: #0080ff;
  font-weight: 500;
}

.qrCodeBorder {
  border-radius: 4.8px;
  border: 2px solid #f0f0f0;
  padding: 5px;
}

.textCode {
  font-weight: 500;
}
