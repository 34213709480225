@import "../../styles/global/_global-dir";

.RequestMainContent {
  composes: app-login-main-content from global;
  flex-direction: column !important;
  align-items: center;
  //padding: 2rem 2.7rem;
  form {
    display: flex;
    flex-direction: column;
  }
  &:not(.Success) form {
    width: 24.27vw;
  }
  position: relative;
  overflow: unset;
  small {
    margin-top: 0.5295rem;
    font-size: 0.8235rem;
    line-height: 1.829;
  }
  :global(p.Mui-error) {
    //margin-top: rem(-10px) !important;
    top: calc(100% - #{rem(17px)});
  }
  :global(div.Mui-error) {
    //margin-top: rem(-10px) !important;
    margin-bottom: rem(19px);
  }
}
img.Logo {
  height: 2.7rem;
  margin-bottom: 2.3rem;
}
